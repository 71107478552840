



























































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import RequestHandler from "@/assets/ts/requestHandler";
import smartyStreets from "@/components/smarty-streets/smarty-streets.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import vueDebounce from "vue-debounce";
Vue.use(vueDebounce);

const RH = new RequestHandler();

@Component({
  components: {
    VuePhoneNumberInput,
    smartyStreets
  }
})
export default class EditUserAccount extends Vue {
  private userID = 0;
  private credentialError = false;
  private serverError = false;
  private showOldPass = false;
  private showNewPass = false;
  private userCustomerInfo: any = {};
  private userInfoValid = true;
  private bankInfoValid = true;
  private emailAtLoad = "";
  private firstName = "";
  private lastName = "";
  private addressLine1 = "";
  private routingNoErrorMsg = "";
  private accountNoErrorMsg = "";
  private accountNameErrorMsg = "";
  private bankValidationType = 0;
  private bankName = "";
  private useCreditCard = false;
  private useEnteredAddress = false;
  private configLoaded = false;
  private config = {};
  private userInfo = {
    businessTypeBoxItems: [] as {}[],
    stateBoxItems: []
  };
  private password = {
    oldPassword: "",
    newPassword: "",
    active: false,
    valid: true,
    attemptCount: 0
  };
  private lockIcon: any = {
    status: 1,
    iconName: "mdi-lock-open-check",
    textDisplay: "Change Password"
  };
  private smartyStreetsVuetifyProps = {
    outlined: true,
    dense: true
  };
  private tab = 0;
  private loading = false;
  private rules = {
    required: (value: string | number) => !!value || "This field is required.",
    email: (value: string) => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid email address.";
    },
    zipcode: (value: string) => {
      const pattern = /^\d{5}(-\d{4})?$/;
      return pattern.test(value) || "Invalid zip code.";
    },
    phone: (value: string) => {
      const pattern = /^\d{10}$/;
      return pattern.test(value) || "Invalid phone number.";
    },
    dropdown: (value: number) =>
      (!!value && value != -1) || "This field is required.",
    oldPassword: (value: string) => {
      if (
        !(this.password.oldPassword == "" && this.password.newPassword == "") &&
        !(this.password.oldPassword && this.password.newPassword)
      ) {
        return "Both password fields must be filled out or left blank.";
      }
      return true;
    },
    password: (value: string) => {
      const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_+={[}\]|\\:;"'<,>.?/-])[A-Za-z\d~`!@#$%^&*()_+={[}\]|\\:;"'<,>.?/-]{8,}$/;
      if (
        !(this.password.oldPassword == "" && this.password.newPassword == "")
      ) {
        if (!(this.password.oldPassword && this.password.newPassword)) {
          return "Both password fields must be filled out or left blank.";
        } else if (!pattern.test(value)) {
          return "Password must contain 1 lowercase letter, one uppercase letter, 1 number, 1 special character, and be at least 8 characters in total.";
        }
      }
      return true;
    }
  };
  get userInfoForm(): Vue & { validate: () => boolean } {
    return this.$refs.userInfoForm as Vue & { validate: () => boolean };
  }

  get bankInfoForm(): Vue & { validate: () => boolean } {
    return this.$refs.bankInfoForm as Vue & { validate: () => boolean };
  }

  get passwordForm(): Vue & { validate: () => boolean } {
    return this.$refs.passwordForm as Vue & { validate: () => boolean };
  }

  //TODO - This function should be replaced with direct calls to passwordForm.validate()
  private validatePasswordForm() {
    this.passwordForm.validate();
  }

  private async validateRoutingNo() {
    try {
      const response = await RH.validateRoutingNo(
        this.userCustomerInfo.routingNo
      );
      this.bankName = response.bankName;
      this.routingNoErrorMsg = "";
    } catch (e) {
      this.bankName = "";
      this.routingNoErrorMsg = "Invalid routing number.";
    }
  }

  private validateAccountNo() {
    const pattern = /^[a-zA-Z0-9\\*]+$/;
    if (pattern.test(this.userCustomerInfo.accountNo)) {
      this.accountNoErrorMsg = "";
    } else {
      this.accountNoErrorMsg =
        "Account numbers may contain only letters and numbers.";
    }
  }

  private async validateBankForm() {
    if (
      [null, ""].includes(this.userCustomerInfo.accountNo) &&
      [null, ""].includes(this.userCustomerInfo.routingNo) &&
      [null, ""].includes(this.userCustomerInfo.accountName) &&
      this.useCreditCard
    ) {
      this.accountNoErrorMsg = "";
      this.routingNoErrorMsg = "";
      this.accountNameErrorMsg = "";
      this.bankName = "";
      this.bankInfoValid = true;
    } else if (
      ![null, ""].includes(this.userCustomerInfo.accountNo) &&
      ![null, ""].includes(this.userCustomerInfo.routingNo) &&
      ![null, ""].includes(this.userCustomerInfo.accountName)
    ) {
      this.validateRoutingNo();
      this.validateAccountNo();
      if (this.routingNoErrorMsg === "" && this.accountNoErrorMsg === "") {
        this.accountNameErrorMsg = "";
        this.bankInfoValid = true;
      } else {
        this.bankInfoValid = false;
      }
    } else {
      this.accountNoErrorMsg = "";
      this.routingNoErrorMsg = "";
      this.accountNameErrorMsg = "";
      if ([null, ""].includes(this.userCustomerInfo.accountNo)) {
        this.accountNoErrorMsg = "Please enter an account number.";
      } else {
        this.validateAccountNo();
      }
      if ([null, ""].includes(this.userCustomerInfo.routingNo)) {
        this.bankName = "";
        this.routingNoErrorMsg = "Please enter a routing number.";
      } else {
        this.validateRoutingNo();
      }
      if ([null, ""].includes(this.userCustomerInfo.accountName)) {
        this.accountNameErrorMsg = "Please enter an account name.";
      }
      this.bankInfoValid = false;
    }
  }

  public toggleLockIcon() {
    if (this.lockIcon.status == 1) {
      this.lockIcon = {
        status: 2,
        iconName: "mdi-lock",
        textDisplay: "Disable Fields"
      };
      this.password.active = true;
      this.password.valid = false;
    } else if (this.lockIcon.status == 2) {
      this.lockIcon = {
        status: 1,
        iconName: "mdi-lock-open-check",
        textDisplay: "Change Password"
      };
      this.password.active = false;
      this.password.valid = true;
    }
    this.password.oldPassword = "";
    this.password.newPassword = "";
  }

  private async updateAccount() {
    try {
      const emailExists = await RH.checkForExistence(
        "email",
        this.userCustomerInfo.primaryUser.email
      );
      if (
        emailExists &&
        this.userCustomerInfo.primaryUser.email !== this.emailAtLoad
      ) {
        this.$root.$emit(
          "snackbar-message",
          "Email is taken. Please choose another."
        );
        return false;
      }
    } catch (e) {
      this.$root.$emit(
        "snackbar-message",
        "Error checking to determine if email is unique."
      );
      return false;
    }

    this.validateBankForm();
    if (
      !this.userInfoValid ||
      !this.bankInfoValid ||
      (this.password.active && !this.password.valid)
    ) {
      this.$root.$emit(
        "snackbar-message",
        "Please ensure all required fields have valid information."
      );
      return false;
    }

    this.loading = true;
    this.userCustomerInfo.vtrsCustomer.customerEMail = this.userCustomerInfo.primaryUser.email;
    this.userCustomerInfo.primaryUser.fullname =
      this.firstName + " " + this.lastName;
    const firstSpace = this.addressLine1.indexOf(" ");
    const streetNo = this.addressLine1.slice(0, firstSpace);
    this.userCustomerInfo.vtrsCustomer.physicalAddress.streetNo = streetNo;
    const address1 = this.addressLine1.slice(firstSpace + 1);
    this.userCustomerInfo.vtrsCustomer.physicalAddress.address1 = address1;
    const userID = this.$store.getters.userInfo.userID;
    const username = this.$store.getters.userInfo.username;
    const password = this.password.oldPassword;
    const newPassword = this.password.newPassword;
    let confirmationResponse = {} as any;

    if (this.password.active) {
      try {
        confirmationResponse = await RH.confirmPassword({
          username,
          password
        });
      } catch (e) {
        this.loading = false;
        this.password.attemptCount++;
        if (this.password.attemptCount > 4) {
          this.$root.$emit(
            "snackbar-message",
            "Maximum password attempts exceeded"
          );
          await RH.logout(this.$store.getters.userInfo.token);
          this.$router.push({ name: "Login" });
          return;
        } else {
          this.$root.$emit("snackbar-message", "Old password is incorrect");
          return;
        }
      }
      this.password.attemptCount = 0;
      try {
        await RH.updatePassword(
          userID,
          newPassword,
          confirmationResponse.token
        );
        try {
          await RH.blackListToken(confirmationResponse.token);
        } catch {
          //Confirmation token blacklist failure
        }
      } catch {
        this.$root.$emit(
          "snackbar-message",
          "Password and Account edit failed"
        );
        this.loading = false;
        return;
      }
    }

    try {
      const response = await RH.editCustomer(this.userCustomerInfo);
    } catch (e) {
      if (this.password.active) {
        this.$root.$emit(
          "snackbar-message",
          "Password edit was successful, but Account edit failed. Please log back in with your new password."
        );
        this.loading = false;
        await RH.logout(this.$store.getters.userInfo.token);
        this.$router.push({ name: "Login" });
      } else {
        this.loading = false;
        this.$root.$emit(
          "snackbar-message",
          "Account update failed due to a server error."
        );
      }
      return;
    }

    if (this.password.active) {
      this.$root.$emit(
        "snackbar-message",
        "Password and Account edit were successful. Please log back in with your new password."
      );
      await RH.logout(this.$store.getters.userInfo.token);
      this.$router.push({ name: "Login" });
    } else {
      this.$root.$emit(
        "snackbar-message",
        "Account edit submitted successfully"
      );
      this.$router.push({ name: "Search" });
    }
    this.loading = false;
  }

  private clearBankFields() {
    this.userCustomerInfo.routingNo = "";
    this.bankName = "";
    this.userCustomerInfo.accountNo = "";
    this.userCustomerInfo.accountName = "";
    this.routingNoErrorMsg = "";
    this.accountNoErrorMsg = "";
    this.accountNameErrorMsg = "";
  }

  async beforeCreate() {
    this.config = await RH.getSmartyStreetsConfig();
    this.configLoaded = true;
  }

  async created() {
    this.userInfo.stateBoxItems = await RH.getStates();
    this.userID = this.$store.getters.userInfo.userID;
    this.userInfo.businessTypeBoxItems = await RH.getCustomerTypes();
    if (this.credentialError) {
      this.credentialError = false;
    }
    if (this.serverError) {
      this.serverError = false;
    }

    try {
      this.userCustomerInfo = await RH.getUserCustomerInfo(this.userID);
      this.emailAtLoad = this.userCustomerInfo.primaryUser.email;
      const firstSpace = this.userCustomerInfo.primaryUser.fullname.indexOf(
        " "
      );
      this.firstName = this.userCustomerInfo.primaryUser.fullname.slice(
        0,
        firstSpace
      );
      this.lastName = this.userCustomerInfo.primaryUser.fullname.slice(
        firstSpace + 1
      );

      this.addressLine1 =
        this.userCustomerInfo.vtrsCustomer.physicalAddress.streetNo +
        " " +
        this.userCustomerInfo.vtrsCustomer.physicalAddress.address1;
      if (this.userCustomerInfo.routingNo?.length > 0) {
        this.validateRoutingNo();
      }

      this.useCreditCard =
        [null, ""].includes(this.userCustomerInfo.accountNo) ||
        [null, ""].includes(this.userCustomerInfo.routingNo);
    } catch (e) {
      if (e.response === undefined) {
        this.serverError = true;
        return;
      }

      switch (e.response.status) {
        case 500:
          this.serverError = true;
          break;
        case "PermissionsError":
          this.serverError = true;
          break;
        default:
          this.credentialError = true;
          break;
      }
    }
  }
}
